import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slideshow from "../components/bb/slideshow"
import Button from "../components/bb/button"
import Smartgrid from "../components/bb/smartgrid"
import Card from "../components/bb/card"
import Img from "gatsby-image"
// import Form from "../components/bb/form"
// import { css } from "@emotion/react"

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="" />
      <Slideshow imagefilter='featured'/>
      <div id="overview">
        <h1>Experience Crooked Creek in Boscobel, Wisconsin</h1>
        <p>
        Boscobel, located on the lower Wisconsin River, is a gateway to the rolling hills and meandering streams of the
        unglaciated driftless region of Wisconsin. Boscobel offers a rich scenic beauty and a peaceful charm.The town is known
        for its hunting and fishing as well as being the birthplace of the Gideon Bible. Our Crooked Creek Cabins are conveniently
        located just south of the town on 450 acres. The Crooked Creek runs through the property offering fishing and hunting opportunities close by.
        </p>
      </div>

      <div id="cabins">
        <h1>Our Featured Rental Cabins</h1>
        <Smartgrid maxcolumns={2} >
          <Card>
            <Img fluid={data.cabin1.childImageSharp.fluid} alt="cabin1" />
            <h5>The Expansive Retreat</h5>
            <h6>13 guests · 5 bedrooms · 6 beds · 2.5 baths</h6>
            <Button 
              value="Details"
              to="/expansive-retreat/"
            />
          </Card>
          <Card >
            <Img fluid={data.cabin2.childImageSharp.fluid} alt="cabin2" />
            <h5>The Rustic Cabin</h5>
            <h6>4 guests · 2 bedrooms · 3 beds · 1 bath</h6>
            <Button 
              value="Details"
              to="/rustic-cabin/"
            />
          </Card>
          <Card >
            <Img fluid={data.cabin3.childImageSharp.fluid} alt="cabin3" />
            <h5>The Serenity Cabin</h5>
            <h6>6 guests · 2 bedrooms · 3 beds · 1 bath</h6>
            <Button 
              value="Details"
              to="/serenity-cabin/"
            />
          </Card>
          <Card >
            <Img fluid={data.cabin4.childImageSharp.fluid} alt="cabin4" />
            <h5>The Relaxation Cabin</h5>
            <h6>2 guests · 1 bedrooms · 1 beds · 1 bath</h6>
            <Button 
              value="Details"
              to="/relaxation-cabin/"
            />
          </Card>
        </Smartgrid>
      </div>
      <div id="area-info">
        <h1>Boscobel Area Information</h1>
        <p>
          Boscobel and the surrounding area draws recreation and adventure seekers year round for camping, hiking, canoeing, 
          kayaking, bicycling, fly fishing, rafting, snowmobiling, hunting and much more.
          </p>
        <p>
          We have vacation cabins located conveniently near all the activities that the this beautiful landscape has to offer. More information 
          and resources about area attractions and local events can be found by following this link to our <Link to="/area-information/">Area Information</Link> page. 
        </p>
      </div>
      {/* <div id="contact">
        <h1>Contact Us</h1>
        <p>Have questions about Crooked Creek Cabins? Send us a message with the form below:</p>
        http://localhost:5001/crookedcreekcabins/us-central1/emailMessage
        https://us-central1-crookedcreekcabins.cloudfunctions.net/emailMessage
        <Form action="https://us-central1-crookedcreekcabins.cloudfunctions.net/emailMessage">
          <input name="name" type="text" placeholder="name" required />
          <input name="email" type="email" placeholder="email" required />
          <textarea name="message" placeholder="message" required />
          <input id="submit" type="submit" value="Send" />
        </Form>
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    cabin1: file(relativePath: { regex: "/cabin1-a.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 305) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cabin2: file(relativePath: { regex: "/cabin2-a-featured.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 305) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cabin3: file(relativePath: { regex: "/cabin3-a-featured.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 305) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cabin4: file(relativePath: { regex: "/cabin4-a-featured.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 305) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
// site {
//   siteMetadata {
//     title
//     description
//     author
//   }
// }

// allMarkdownRemark = Query info
// for *.md file content loaded into page
// allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//   totalCount
//   edges {
//     node {
//       id
//       frontmatter {
//         title
//         date(formatString: "DD MMMM, YYYY")
//       }
//       fields {
//         slug
//       }
//       excerpt
//     }
//   }
// }